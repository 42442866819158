<template>
    <div
        :class="['order-card', {'disabled-card': order.disabled, 'card-hover': hasHover}] "
        :style="`border-left: 4px solid ${order.color};`"
    >
        <div class="info-top-order">
            <div class="top-content-1">
                <div :class="['custom-checkbox', {'selected-checkbox': order.selected}]" v-show="selectable">
                    <div
                        v-show="order.selected"
                        class="d-flex align-items-center justify-content-center"
                    >
                        <CheckIcon v-if="windowWidth > 480"/>
                        <CheckIconSmall v-else/>
                    </div>
                </div>
                <span>
                    {{ `${order.order_no}/${order.op_no}` }}
                </span>
            </div>
            <div :class="['top-content-2', {'self-start': order.is_rework == 1}]">
                <div class="status-rework-container">
                    <div class="rework-status" :style="`color:${order.color}`" v-if="order.is_rework == 1">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" fill="none">
                            <path :fill="order.color" d="M9.4425 1.75758C7.134 -0.551416 3.4145 -0.580416 1.0645 1.66008L0
                            0.531084V4.50008H3.742L2.709 3.40458C4.119 2.05158 6.3575 2.06658 7.7455 3.45458C9.916 5.62508
                            8.614 9.60008 5 9.60008V12.0001C6.854 12.0001 8.307 11.3781 9.4425 10.2426C11.7855 7.89958
                            11.7855 4.10058 9.4425 1.75758Z"/>
                        </svg>
                        {{ $t('Operator.Barcode.Rework') }}
                    </div>
                    <div class="order-status">
                        <span :style="getBackgroundColorLegend(order.color)">
                            {{ order.status }}
                        </span>
                    </div>
                </div>

                <div class="delete-button" v-if="showDeleteButton" @click="deleteAction">
                    <DeleteIcon />
                </div>
            </div>
        </div>

        <div class="info-bottom">
            <div class="info-group">
                <div class="info-container">
                    <span class="info-title">{{ $t('Operator.Barcode.Resource') }}</span>
                    <span class="info-content">{{ order.resource }}</span>
                </div>
                <div class="info-container">
                    <span class="info-title">{{ $t('Operator.Barcode.Product') }}</span>
                    <span class="info-content">{{ order.product }}</span>
                </div>
                <div class="info-container">
                    <span class="info-title"> {{ $t('Operator.Barcode.Operation') }}</span>
                    <span class="info-content">{{ order.operation_name }}</span>
                </div>
            </div>
            <div class="info-group">
                <div class="info-container">
                    <span class="info-title">{{ $t('ordersList.SetupStart') }}</span>
                    <span class="info-content">{{ getFormatedDate(order.setup_start) }}</span>
                </div>
                <div class="info-container">
                    <span class="info-title">{{ orderType === 'Finished' ? $t('Operator.StartOperation') : $t('Operator.StartTime') }}</span>
                    <span class="info-content">{{ getFormatedDate(order.start_time) }}</span>
                </div>
                <div class="info-container">
                    <span class="info-title"> {{ orderType === 'Finished' ? $t('Operator.EndOperation') : $t('Operator.EndTime') }}</span>
                    <span class="info-content">{{ getFormatedDate(order.end_time) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import CheckIcon from '@/assets/images/icons/check-simple-1.svg';
import CheckIconSmall from '@/assets/images/icons/check-simple-2.svg';
import ReworkIcon from '@/assets/images/new-icons/rework-icon.svg';
import DeleteIcon from '@/assets/images/new-icons/trash-icon-2.svg';

export default {
    components: {
        CheckIcon,
        CheckIconSmall,
        DeleteIcon,
        ReworkIcon
    },
    props: {
        selectable: {
            type: Boolean,
            default: false
        },
        order: {
            type: Object,
            default: () => {
                return {

                }
            }
        },
        orderType: {
            type: 'Normal' | 'Finished',
            default: 'Normal'
        },
        hasHover: {
            type: Boolean,
            default: true
        },
        showDeleteButton: {
            type: Boolean,
            default: false
        },
        deleteAction: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            windowWidth: window.innerWidth,
        }
    },
    methods: {
        getFormatedDate(date) {
            if(!date) return '';

            const language = this.$cookies.get('userInfo').language;
            if (language == 'pt-BR') return moment(date).format('DD-MM-YYYY HH:mm');

            return moment(date).format('MM-DD-YYYY HH:mm');
        },
        getBackgroundColorLegend(color) {
            if (color) return `color:${color}; background-color:${color}20`;

            return `background-color:secondary`;
        },
    }
}
</script>

<style lang="scss" scoped>

.row-orders {
    margin-top: 4rem;
    width: 100%;
}

.list-orders {
    gap: 16px;
    width: 100%;
}

.order-card {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 24px 16px;
    user-select: none;
    cursor: pointer;
    filter: drop-shadow(0px 4px 24px rgba(58, 46, 38, 0.1));

    &.card-hover {
        &:hover {
            background: #ffede2;
        }

        &:active {
            background: #ffdbc4;
        }
    }

    &.disabled-card {
        opacity: 0.5;

        .custom-checkbox {
            background: #ECE0DB !important;
        }
    }

    .info-top-order {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        .top-content-1 {
            min-width: calc(50% - 8px);
            display: flex;
            align-items: center;
            flex: 1;
            .custom-checkbox {
                width: 24px;
                height: 24px;

                background: #ffff;
                border: 1px solid #CFC4BE;
                border-radius: 3px;

                margin-right: 16px;

                display: flex;
                align-items: center;
                justify-content: center;

                &.selected-checkbox {
                    background: #974900;
                }
            }

            span {
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #4C4541;
                margin-right: auto;
                padding: 0;
            }
        }

        .top-content-2 {
            max-width: calc(50% - 8px);
            display: flex;
            justify-content: end;
            align-items: center;
            gap: 16px;

            &.self-start {
                align-self: self-start;
            }

            .status-rework-container {
                display: flex;
                flex-direction: column;
                align-items: end;
                gap: 8px;
                .rework-status {
                    color: #F44336;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    min-width: max-content;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;

                    svg {
                        height: 12px;
                        width: 12px;
                    }
                }
                .order-status {
                    min-height: 18px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    span {
                        border-radius: 4px;
                        padding: 1px 5.5px;

                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;

                        word-break: break-all;
                        white-space: pre-wrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }

            }
        }

        .delete-button {
            padding: 7px;
            border: 1px solid #D32F2F;
            border-radius: 5px;
            display: flex;
            cursor: pointer;

            &:hover {
                background-color: #FFCDD2;
            }

            svg {
                height: 12px;
                width: 12px;
            }
        }
    }

    .info-bottom {
        display: flex;
        flex-direction: column;
        padding-top: 24px;

        .info-group {
            padding-top: 16px;
            display: flex;

            &:first-child {
                padding: 0;
            }

            .info-container {
                width: calc(100% / 3);
                display: flex;
                flex-direction: column;
                padding-right: 16px;

                &:last-child {
                    padding: 0;
                }

                .info-title {
                    font-size: 12px;
                    line-height: 16px;
                    color: #998F8A;
                }

                .info-content {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #4C4541;

                    /* max-width: 100%; */
                    word-break: break-all;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    white-space: pre-wrap;
                }
            }
        }
    }
}

@media (max-width: 768px) and (min-width: 425px) {
    .order-card {
        height: auto;

        .info-top-order {

            span {
                font-size: 26px;
                line-height: 36px;
            }


            .top-content-1 {
                span {
                    font-size: 26px !important;
                    line-height: 36px !important;
                }
            }

            .top-content-2 {
                .status-rework-container {
                    .order-status {
                        max-width: 329px;
                        min-height: 28px;

                        span {
                            font-size: 18px !important;
                            line-height: 26px !important;
                        }
                    }
                    .rework-status {
                        font-size: 14px;
                        line-height: 20px;

                        svg {
                            height: 16px;
                            width: 16px;
                        }
                    }
                }

                .delete-button {
                    padding: 11px;

                    svg {
                        height: 14px;
                        width: 14px;
                    }
                }
            }
        }

        .info-bottom {
            padding-top: 24px;

            .info-group {
                gap: 0;

                .info-container {
                    width: calc((100% / 3) - (10px / 3));
                }

                .info-title {
                    font-size: 16px !important;
                    line-height: 22px !important;
                }

                .info-content {
                    font-size: 18px !important;
                    line-height: 26px !important;
                }
            }
        }
    }
}

@media (max-width: 593px) and (min-width:425px) {
    .order-card {
        height: auto;

        .ellipsis-card-scanned {
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .info-top-order {
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 24px;

            .top-content-1 {
                max-width: none;
                margin: 0;
            }

            .top-content-2 {
                flex-direction: row;
                justify-content: space-between;
                align-items: start;
                max-width: none;
                width: 100%;

                .status-rework-container {
                    flex: 1;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: start;
                    .order-status {
                        order: -1;
                        align-items: flex-start;
                        max-width: 100%;
                    }
                }
            }
        }

        .info-bottom {
            .info-resource {
                .info-title {
                    display: none;
                }
            }

            .info-group {
                padding-top: 0;
                gap: 16px 0;

                flex-direction: column;

                &:nth-child(2) {
                    padding: 16px 0 0 0 !important;
                }

                .info-container {
                    width: 100% !important;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    padding: 0 !important;

                    .info-title {
                        padding-right: 16px;
                    }

                    .info-content {
                        text-align: right;
                    }
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .order-card {
        height: auto;
        padding: 16px;

        .ellipsis-card-scanned {
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .info-top-order {
            flex-direction: column-reverse;
            align-items: flex-start;

            .top-content-1 {
                font-size: 18px !important;
                line-height: 26px !important;
                max-width: none;
                margin: 0;
            }

            .top-content-2 {
                flex-direction: row;
                justify-content: space-between;
                align-items: start;
                max-width: none;
                width: 100%;

                .status-rework-container {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: start;
                    flex: 1;
                    .order-status {
                        order: -1;
                        max-width: 100%;

                        span {
                            font-size: 12px !important;
                            line-height: 16px !important;
                        }
                    }

                    .rework-status {
                        align-items: center;
                    }
                }
            }

        }

        .info-bottom {
            padding-top: 16px;

            .info-resource {
                .info-title {
                    display: none;
                }
            }

            .info-group {
                padding-top: 0;
                gap: 8px 0;

                flex-direction: column;

                &:nth-child(2) {
                    padding: 8px 0 0 0 !important;
                }

                .info-container {
                    width: 100% !important;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    padding: 0 !important;

                    .info-title {
                        font-size: 12px !important;
                        line-height: 16px !important;
                        padding-right: 16px;
                    }

                    .info-content {
                        font-size: 14px !important;
                        line-height: 20px !important;
                        text-align: right;
                    }
                }
            }
        }
    }
}
</style>
